<template>
  <div class="scroll-container lists-container">
    <CategoryTitle :category="category" class="hidden-xs-only" />
    <ListsMenu
      :listsData="lists"
      @setdefault="setDefaultList"
      @remove="removeList"
      @createlist="createListt"
      @rename="renameList"
    />

    <v-card
      light
      outlined
      v-if="lists && lists.length == 0"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title class="headline">Nessuna lista trovata</v-card-title>
    </v-card>
  </div>
</template>
<style lang="scss">
.lists-container {
  .v-speed-dial {
    position: fixed;
    bottom: 80px;
  }
  .lists-filter.v-speed-dial--is-active {
    .v-speed-dial__list {
      .v-card {
        overflow-y: auto;
        max-height: calc(100vh - 250px);
        border-radius: $border-radius-root;
        border: 1px solid var(--v-grey-lighten1);
        -webkit-box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
        box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
// import ProfileTitle from "@/components/profile/ProfileTitle";

import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListsMenu from "@/components/lists/ListsMenu.vue";

import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Lists",
  mixins: [list, categoryMixins, deliveryReactive],
  data() {
    return {
      page: {},
      listId: null,
      fab: false,
      prof: false
    };
  },
  components: {
    CategoryTitle,
    ListsMenu
  },
  methods: {
    checkListId() {},
    createListt(obj) {
      this.listName = obj.name;
      this.createList();
    }
  },
  created() {
    this.reload();
  },
  watch: {
    $route(to) {
      this.listId = to.params.listId;
    }
  }
};
</script>
