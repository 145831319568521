<template>
  <div class="list-menu">
    <div class="text-body-2 secondary--text font-weight-bold mt-5 mb-2">
      {{ $t("lists.header.create") }}
    </div>
    <div
      class="d-flex flex-row align-end"
      :class="{ 'w-50': $vuetify.breakpoint.smAndUp }"
    >
      <v-text-field
        :placeholder="$t('lists.insert')"
        hide-details="auto"
        class="text-body-2 pr-2"
        v-model="listName"
      ></v-text-field>
      <v-btn
        v-ripple="false"
        depressed
        class="primary ml-3"
        width="98"
        v-on:click="createList()"
        :disabled="createDisabled"
        >{{ $t("lists.button.create") }}</v-btn
      >
    </div>
    <v-list class="pa-0 default--text mt-8">
      <v-list-item-title class="text-body-1 font-weight-bold mb-5">
        {{ $t("lists.header.automaticLists") }}
        <v-tooltip right content-class="automatic-list-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" v-bind="attrs" v-on="on">$info</v-icon>
          </template>
          <span>{{ $t("lists.header.automaticListsTooltip") }}</span>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item
        class="rounded-sm text-body-2 secondary--text font-weight-bold py-2"
        :to="{ name: 'List', params: { listId: 'preferred' } }"
      >
        <i18n
          :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
          tag="strong"
        />
      </v-list-item>

      <v-list-item-title class="text-body-1 font-weight-bold mt-8 mb-5">
        {{ $t("lists.header.myLists") }}
        <v-tooltip right content-class="my-list-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" v-bind="attrs" v-on="on">$info</v-icon>
          </template>
          <span>{{ $t("lists.header.myListsTooltip") }}</span>
        </v-tooltip>
      </v-list-item-title>

      <v-row no-gutters class="mt-2 default--text font-weight-bold">
        <v-col cols="6" sm="2">{{ $t("lists.header.name") }}</v-col>
        <v-col cols="6" sm="2" class="pl-2">
          {{ $t("lists.header.productsQty") }}</v-col
        >
      </v-row>
      <v-list-item
        v-for="list in listsData"
        :key="list.id"
        class="rounded-sm my-2 py-2"
        :to="{ name: 'List', params: { listId: list.id } }"
      >
        <v-row
          no-gutters
          align="center"
          class="text-body-2"
          v-if="!list.isEdit"
        >
          <v-col cols="6" sm="2">
            <strong class="secondary--text font-weight-bold">{{
              list.name
            }}</strong>
          </v-col>
          <v-col cols="6" sm="2" class="secondary--text">
            {{ list.size }}
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="d-flex justify-end align-center mt-6 mt-sm-0"
          >
            <v-tooltip top v-if="!list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.prevent="setDefault(list.id)"
                  class="secondary--text"
                >
                  <v-icon>$star</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("lists.button.setDefault") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.prevent=""
                  class="secondary--text"
                >
                  <v-icon>$starFull</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("lists.button.default") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  depressed
                  v-bind="attrs"
                  width="40"
                  min-width="40"
                  v-on="on"
                  @click.prevent="addAllToCart(list.id)"
                  class="ml-3"
                >
                  <v-icon small>$cart</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("lists.button.addAllToCart") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  depressed
                  v-bind="attrs"
                  width="40"
                  min-width="40"
                  v-on="on"
                  @click.prevent="setEdit(list)"
                  class="secondary--text ml-3"
                >
                  <v-icon small>$edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("lists.button.edit") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  depressed
                  width="40"
                  min-width="40"
                  v-bind="attrs"
                  v-on="on"
                  class="secondary--text ml-3"
                  @click.prevent="removeList(list.id, list.name)"
                  :disabled="list.isDefault"
                >
                  <v-icon small>$delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("lists.button.delete") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row no-gutters align="center" v-if="list.isEdit">
          <!-- label="Inserisci il nome" -->
          <v-col cols="8">
            <v-text-field
              hide-details="auto"
              dense
              outlined
              class="text-body-2 pr-2"
              v-model="list.tempName"
              v-on:click.prevent="() => {}"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="d-flex flex-row">
            <v-btn icon v-on:click.prevent="confirmEdit(list)">
              <v-icon color="secondary">$done</v-icon>
            </v-btn>
            <v-btn icon v-on:click.prevent="undoEdit(list)">
              <v-icon color="secondary">$close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.list-menu {
  .v-list {
    background-color: transparent;
    .v-list-item {
      background-color: $white;
      border: 1px solid var(--v-grey-lighten1);
    }
  }
}
</style>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import list from "~/mixins/list";

export default {
  name: "ListsMenu",
  mixins: [list],
  props: {
    listsData: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    createList() {
      let name = this.listName;
      this.$emit("createlist", { name });
    },
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(listId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(listId);
      }
    },
    setOpenList(listId) {
      this.setOpenedListId(listId);
    }
  }
  // watch: {
  //   lists() {
  //     console.log(this.lists);
  //   }
  // }
};
</script>
